/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import moment from 'moment';
import '../Css(All)/Calendar.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TeacherCalendar = () => {
  const [formData, setFormData] = useState({
    teacherName: '',
    batchName: '',
    batchCode: '',
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: '',
  });

  const [teachers, setTeachers] = useState([]);
  const [batches, setBatches] = useState([]);
  const [view, setView] = useState('');
  const [scheduleData, setScheduleData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const teacherDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const teacherId = teacherDetails ? teacherDetails.TeacherID : null;

  useEffect(() => {
    if (teacherDetails) {
      setFormData((prev) => ({
        ...prev,
        teacherName: teacherDetails.TeacherName,
      }));
    }
    
  }, [teacherDetails]);

  // Fetch Teacher Data
  const fetchTeachers = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/teacherCalendar`);
      const filteredTeachers = response.data.filter(
        (teacher) => teacher.TeacherID === teacherId
      );
      setTeachers(filteredTeachers);
    } catch (error) {
      console.error('Error fetching teacher data:', error);
    }
  };

  // Fetch Batches for the selected Teacher
  const fetchBatches = async () => {
    if (!teacherId) return;

    try {
      const response = await axios.post(`${API_BASE_URL}/api/getBatchesByTeacher`, { teacherId });
      setBatches(response.data);
    } catch (error) {
      console.error('Error fetching batch data:', error);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  // Handle Batch selection
  const handleBatchSelect = (e) => {
    const selectedBatchCode = e.target.value;
    const selectedBatch = batches.find((batch) => batch.BatchCode === selectedBatchCode);
    if (selectedBatch) {
      setFormData((prev) => ({
        ...prev,
        batchName: selectedBatch.BatchName,
        batchCode: selectedBatch.BatchCode,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        batchName: '',
        batchCode: '',
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { fromDate, toDate, batchCode } = formData;

    try {
      const response = await axios.post(`${API_BASE_URL}/api/getBatchTimes`, {
        teacherId,
        fromDate,
        toDate,
        batchCode,
      });
      setScheduleData(response.data);
      setView('calendar');
    } catch (error) {
      console.error('Error fetching batch times:', error);
    }
  };

  // Get the dates with schedule
  const getScheduledDates = () => {
    return scheduleData.map(schedule => moment(schedule.ClassDate, 'DD/MM/YYYY').toDate());
  };

  // Handle calendar date click
  const handleDateClick = (date) => {
    const classDetails = scheduleData.filter(
      (schedule) => moment(schedule.ClassDate, 'DD/MM/YYYY').isSame(date, 'day')
    );
    setSelectedDate(classDetails);
  };

  useEffect(() => {
    fetchTeachers();
    fetchBatches();
  }, [teacherId]);

  return (
    <div className="teacher-calendar-container">
      <form className='teacher-calendar-form' onSubmit={handleSubmit}>
        <div className="teacher-calendar-content">
          {/* Teacher Name Field */}
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Teacher Name:</label>
                <input
                  type="text"
                  name="teacherName"
                  value={formData.teacherName} // Set the teacher name by default
                  readOnly
                  placeholder="Teacher Name"
                />
              </div>
            </div>
            {/* Batch Name Field */}
            <div className="col-sm-6">
              <div className="form-group">
                <label>Batch Name:</label>
                <select
                  name="batchName"
                  value={formData.batchCode}
                  onChange={handleBatchSelect}
                  placeholder="Select Batch"
                >
                  <option value="">Select Batch (Optional)</option>
                  {batches.map((batch) => (
                    <option key={batch.BatchCode} value={batch.BatchCode}>
                      {batch.BatchName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Date Fields */}
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>From Date:</label>
                <input
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>To Date:</label>
                <input
                  type="date"
                  name="toDate"
                  value={formData.toDate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="teacher-calendar-Button-Container">
            <button className="teacher-calendar-Button" type="submit">Submit</button>
          </div>
        </div>

        {scheduleData.length > 0 && (
          <div className='teacher-calendar-Button-Content'>
            <label className="radio" onClick={() => setView('table')}>
              <input type="radio" name="radio" />
              <span className="name">Table</span>
            </label>
            <label className="radio" onClick={() => setView('calendar')}>
              <input type="radio" name="radio" />
              <span className="name">Calendar</span>
            </label>
          </div>
        )}

        {/* Display Table or Calendar */}
        {view === 'table' && (
          <div className="teacher-calendar-Table-View">
            <table className='teacher-calendar-Table'>
              <thead>
                <tr>
                  <th>Batch Name</th>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Teacher Name</th>
                </tr>
              </thead>
              <tbody>
                {scheduleData.map((schedule, index) => (
                  <tr key={index}>
                    <td>{schedule.BatchName}</td>
                    <td>{schedule.ClassDate}</td>
                    <td>{schedule.ClassStartTime}</td>
                    <td>{schedule.ClassEndTime}</td>
                    <td>{schedule.TeacherName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Calendar View */}
        {view === 'calendar' && (
          <div className="teacher-calendar-Calendar-View">
            <Calendar
              onChange={handleDateClick}
              value={selectedDate}
              tileClassName={({ date, view }) => {
                const scheduledDates = getScheduledDates();
                return scheduledDates.some(d => moment(d).isSame(date, 'day')) ? 'highlighted' : '';
              }}
            />
            {selectedDate && selectedDate.length > 0 && (
              <div className="teacher-calendar-Calendar-popup">
                <div className="teacher-calendar-Calendar-popup-form">
                  <h3>Class for {moment(selectedDate[0].ClassDate, 'DD/MM/YYYY').format('dddd, MMMM Do YYYY')}</h3>
                  <button
                    className="teacher-calendar-Calendar-popup-closebutton"
                    onClick={() => setSelectedDate([])}
                  >
                    &times;
                  </button>
                  <table className='teacher-calendar-Calendar-popup-Table'>
                    <thead>
                      <tr>
                        <th>Batch Name</th>
                        <th>Class Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedDate.map((schedule, index) => (
                        <tr key={index}>
                          <td>{schedule.BatchName}</td>
                          <td>{schedule.ClassDate}</td>
                          <td>{schedule.ClassStartTime}</td>
                          <td>{schedule.ClassEndTime}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}
        
      </form>
    </div>
  );
};

export default TeacherCalendar;
