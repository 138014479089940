import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../Css(All)/TeacherHome.css';
import Profile from './Profile';
import Class from './Class';
import HomeWork from './HomeWork';
import Doubt from './Doubt';
import Calendar from './Calendar';
import Assignment from './Assignment';
import Exam from './Exam';
import LeaveApplication from './LeaveApplication';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Content = ({ selectedMenu }) => {
  const [batches, setBatches] = useState([]);
  const teacherDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const suCode = teacherDetails?.SUCode;
  const teacherId = teacherDetails?.TeacherID;
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchBatches = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/teacher-batches`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ suCode, teacherId }),
        });
        const data = await response.json();
        setBatches(data);
      } catch (error) {
        console.error('Error fetching batches:', error);
      }
    };

    fetchBatches();
  }, [suCode, teacherId]);

  const handleBatchClick = (batchCode) => {
    navigate(`/batch/${batchCode}`);
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case 'Profile':
        return <Profile />;
      case 'Class':
        return <Class />;
      case 'Home Work':
        return <HomeWork />;
      case 'Doubt':
        return <Doubt />;
      case 'Calendar':
        return <Calendar />;
      case 'Assignment':
        return <Assignment />;
      case 'Exam':
        return <Exam />;
      case 'Leave Application':
        return <LeaveApplication />;
      default:
        return (
          <div className="Teacher-Batch">
            <div className="batch-container">
              {batches.map((batch) => (
                <button
                  key={batch.BatchCode}
                  className="batch-button"
                  onClick={() => handleBatchClick(batch.BatchCode)}
                >
                  {batch.BatchName}
                </button>
              ))}
            </div>
          </div>
        );
    }
  };

  return <div className="content">{renderContent()}</div>;
};

export default Content;
