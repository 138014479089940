import React, { useState } from 'react';
import '../Css(All)/LoginTeacher.css';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const LoginTeacher = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();


  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`${API_BASE_URL}/login/teacher`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        sessionStorage.setItem('userDetails', JSON.stringify(data.user));
        navigate('/teacherhome');
      } else {
        setError(data.error || 'Something went wrong');
      }
    } catch (err) {
      console.error(err);
      setError('Network error, please try again');
    }
  };


  const handleGoogleLogin = async (credentialResponse) => {
    try {
      const decoded = jwt_decode(credentialResponse.credential);
      const { email, name, sub } = decoded;

      // Send Google login data to your server
      const response = await fetch(`${API_BASE_URL}/login/teacher/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, name, googleId: sub }),
      });

      const data = await response.json();
      if (response.ok) {
        sessionStorage.setItem('userDetails', JSON.stringify(data.user));
        navigate('/teacherhome');
      } else {
        setError(data.error || 'Google login failed');
      }
    } catch (err) {
      console.error(err);
      setError('Google login failed');
    }
  };

  return (
    <div className='Total-Content'>
      <div className="background">
        <div className='Logo'>
          <img src="https://funscholar.infolabsinsight.com/fabicon.ico" alt="img" />
        </div>
      </div>
      
      <div className="login-Container">
        <div className="back-arrow">
          <Link to="/loginselect">
            <i className="bi-arrow-left"></i>
          </Link>
          <h4 className="login-title">Educator Account</h4>
        </div>

        {error && <p className="error-message">{error}</p>}

        <form className='Educator-login-form' onSubmit={handleLogin}>
          <label className="Educator-input-label">Email ID</label>
          <input
            id="standard-username"
            placeholder="Email ID"
            type="email"
            className="Educator-input-field"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <label className="Educator-input-label">Password</label>
          <input
            id="password"
            placeholder="Enter password"
            type={isPasswordVisible ? 'text' : 'password'}
            className="Educator-input-field"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="password-icon" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
            <svg viewBox="0 0 576 512" height="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path>
            </svg>
          </div>

          <div className="Forgot-Password-Container">
            <a className="forgot-password" href="/forgotpassword">
              <h4>Forgot password?</h4>
            </a>
          </div>

          <button className="login-button" type="submit">
            <span>Log in</span>
            <svg viewBox="0 0 13 10">
              <path d="M1,5 L11,5"></path>
              <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
          </button>
          
          {/* Google Login Button */}
          <div className="Google-button-container">
          <GoogleOAuthProvider clientId="188734846844-5q5iq5aepjc0ad17s5qribosetb512gv.apps.googleusercontent.com">
          <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => setError('Google login failed')}
              />
          </GoogleOAuthProvider>
          </div>


        </form>


      </div>
    </div>
    
  );
};

export default LoginTeacher;
