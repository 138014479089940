import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ClassChapterDetails from "./ClassChapterDetails";
import "../Css(All)/Class.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Class = () => {
  const teacherDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedBatchCode, setSelectedBatchCode] = useState(null);
  const navigate = useNavigate();
  const location = useLocation(); 

  useEffect(() => {
    if (location.state && location.state.batches) {
      setBatches(location.state.batches);
      setLoading(false);
    } else if (teacherDetails?.TeacherID) {
      fetchBatches(teacherDetails.TeacherID);
    } else {
      setError("TeacherID not found in session storage.");
      setLoading(false);
    }
  }, [teacherDetails.TeacherID, location.state]);

  const fetchBatches = async (TeacherID) => {
    setLoading(true);
    setError("");
    try {
      const response = await fetch(`${API_BASE_URL}/api/getClassBatches`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ TeacherID }),
      });

      const data = await response.json();
      if (response.ok) {
        setBatches(data.batches);
      } else {
        setError(data.error || "Failed to fetch batches.");
      }
    } catch (err) {
      setError("Network error, please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleBatchClick = (batchCode, batchName) => {
    navigate(`/ClassChapterDetails/${batchCode}`, {
      state: { batchCode, batchName, batches },
    });
    setSelectedBatchCode(batchCode);
  };

  return (
    <div className="Total-Container">
      <h1>Batch Details</h1>
      {selectedBatchCode ? (
        <ClassChapterDetails batchCode={selectedBatchCode} batches={batches} />
      ) : loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : batches.length > 0 ? (
        <ul className="batch-list">
          {batches.map((batch, index) => (
            <li
              key={index}
              className="batch-item"
              onClick={() => handleBatchClick(batch.BatchCode, batch.BatchName)}
            >
              {batch.BatchName}
            </li>
          ))}
        </ul>
      ) : (
        <p>No batches found for this teacher.</p>
      )}
    </div>
  );
};

export default Class;
