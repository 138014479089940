import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginTeacher from '../All Components/Teacher Login/LoginTeacher';
import TeacherHome from '../All Components/Teacher Part/TeacherHome';
import Content from '../All Components/Teacher Part/Content';
import BatchDetails from '../All Components/Teacher Part/BatchDetails';
import Class from '../All Components/Teacher Part/Class';
import ClassChapterDetails from '../All Components/Teacher Part/ClassChapterDetails';

const RouteConfigs = () => {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<LoginTeacher />} />
      <Route path="/loginteacher" element={<LoginTeacher />} />
      <Route path="/teacherhome" element={<TeacherHome />} />
      <Route exact path="/content" element={<Content />} />
      <Route exact path="/class" element={<Class />} />
      <Route path="/batch/:batchCode" element={<BatchDetails />} />
      <Route path="/ClassChapterDetails/:batchCode" element={<ClassChapterDetails />} />
    </Routes>
    </BrowserRouter>
  )
}

export default RouteConfigs