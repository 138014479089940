import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import '../Css(All)/BatchDetails.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const BatchDetails = () => {
  const { batchCode } = useParams();
  const [chapters, setChapters] = useState({});
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [expandedTopic, setExpandedTopic] = useState(null);
  const [modalData, setModalData] = useState({ isOpen: false, videoUrl: '' });

  useEffect(() => {
    const fetchChaptersAndTopics = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/get-chapters-and-topics`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ batchCode }),
        });
        const data = await response.json();
        setChapters(data);
      } catch (error) {
        console.error('Error fetching chapters and topics:', error);
      }
    };

    fetchChaptersAndTopics();
  }, [batchCode]);

  const handleChapterClick = (chapterCode) => {
    setSelectedChapter((prevChapter) => (prevChapter === chapterCode ? null : chapterCode));
  };

  const handleTopicClick = (topicCode) => {
    setExpandedTopic((prevTopic) => (prevTopic === topicCode ? null : topicCode));
  };

  const handleVideoClick = async (topicCode) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/get-topic-resource`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topicCode }),
      });
      const data = await response.json();
      if (data.VideosUrl) {
        setModalData({ isOpen: true, videoUrl: data.VideosUrl });
      } else {
        alert('No video available for this topic.');
      }
    } catch (error) {
      console.error('Error fetching video URL:', error);
    }
  };

  const handlePptClick = async (topicCode) => {
    try {
      // Send a POST request to the backend to fetch the PPT
      const response = await fetch(`${API_BASE_URL}/api/get-topic-resourceTopic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topicCode }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log(data.message);
      } else {
        alert(data.message || 'Failed to open PPT');
      }
    } catch (error) {
      console.error('Error opening PPT file:', error);
      alert('An error occurred while opening the PPT file.');
    }
  };

  const handleCompleteClick = async (topicCode, chapterCode) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/mark-topic-complete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topicCode }),
      });
      const data = await response.json();

      if (data.success) {
        setChapters((prevChapters) => {
          const updatedChapters = { ...prevChapters };
          Object.keys(updatedChapters).forEach((chapterCodeKey) => {
            if (chapterCodeKey === chapterCode) {
              updatedChapters[chapterCodeKey].Topics.forEach((topic) => {
                if (topic.TopicCode === topicCode) {
                  topic.CompletedOn = data.completedOn;
                  topic.isCompleted = true;
                }
              });
            }
          });
          const chapter = updatedChapters[chapterCode];
          const allTopicsCompleted = chapter.Topics.every((topic) => topic.isCompleted);
          chapter.isCompleted = allTopicsCompleted;

          return updatedChapters;
        });
      } else {
        alert('Failed to mark topic as complete');
      }
    } catch (error) {
      console.error('Error marking topic as complete:', error);
    }
  };

  const closeModal = () => {
    setModalData({ isOpen: false, videoUrl: '' });
  };


  return (
    <div className="batch-details">
      <h2>Chapter Details</h2>
      {Object.keys(chapters).map((chapterCode, chapterIndex) => (
        <div key={chapterCode} className="chapter">
          <h3
            onClick={() => handleChapterClick(chapterCode)}
            style={{
              backgroundColor: chapters[chapterCode].isCompleted ? '#d3ffd3' : 'transparent',
            }}
          >
            <span className="chapter-slno">{chapterIndex + 1}.</span>
            <span className="expand-icon">
              {selectedChapter === chapterCode ? '▼' : '▲'}
            </span>
            {chapters[chapterCode].ChapterName}
            <span className="topic-count">
              {chapters[chapterCode].Topics.length} Topics
            </span>
          </h3>
          {selectedChapter === chapterCode && (
            <ul>
              {chapters[chapterCode].Topics.map((topic, topicIndex) => (
                <li key={topic.TopicCode} className="topic">
                  <div
                    className="topic-container"
                    onClick={() => handleTopicClick(topic.TopicCode)}
                    style={{
                      backgroundColor: topic.isCompleted ? '#d3ffd3' : 'transparent',
                      padding: topic.isCompleted ? '8px' : '0px',
                    }}
                  >
                    <span className="topic-slno">
                      {chapterIndex + 1}.{topicIndex + 1}
                    </span>
                    <span className="expand-icon" style={{
                      fontSize: '12px',
                    }}>
                      {expandedTopic === topic.TopicCode ? '▼' : '▲'}
                    </span>
                    <span className="topic-name">{topic.TopicName}</span>
                    <div className="topic-icons">
                      <span
                        className="icon1"
                        style={{ color: topic.hasPpt ? 'deepblue' : 'lightgray' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTopicClick(topic.TopicCode);
                        }}
                      >
                        <i className="bi bi-file-earmark-richtext"></i>
                      </span>
                      <span
                        className="icon2"
                        style={{ color: topic.hasVideo ? 'deepblue' : 'lightgray' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTopicClick(topic.TopicCode);
                        }}
                      >
                        <i className="bi bi-play-circle"></i>
                      </span>
                      <span
                        className="icon3"
                        style={{ color: topic.hasAssignment ? 'deepblue' : 'lightgray' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTopicClick(topic.TopicCode);
                        }}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </span>
                      <span
                        className="icon4"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTopicClick(topic.TopicCode);
                        }}
                      >
                        <i className="bi bi-film"></i>
                      </span>
                      <span
                        className="icon5"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTopicClick(topic.TopicCode);
                        }}
                      >
                        <i className="bi bi-list-check"></i>
                      </span>
                      <span
                        className="icon6"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTopicClick(topic.TopicCode);
                        }}
                      >
                        <i className="bi bi-graph-up"></i>
                      </span>
                    </div>
                  </div>
                  {/* Show buttons under the topic name if this topic is expanded */}
                  {expandedTopic === topic.TopicCode && (
                    <div className="topic-buttons">
                      <div
                        className="first-button"
                        onClick={() => handlePptClick(topic.TopicCode)}
                        style={{ color: topic.hasPpt ? 'deepblue' : 'lightgray' }}
                      >
                        <i className="bi bi-file-earmark-richtext"></i>
                        PPT
                      </div>
                      <div
                        className="second-button"
                        onClick={() => handleVideoClick(topic.TopicCode)}
                        style={{ color: topic.hasVideo ? 'deepblue' : 'lightgray' }}
                      >
                        <i className="bi bi-play-circle"></i>
                        VIDEO
                      </div>
                      <div
                        className="third-button"
                        onClick={() => console.log('Assignment Clicked')}
                        style={{ color: topic.hasAssignment ? 'deepblue' : 'lightgray' }}
                      >
                        <i className="bi bi-pencil-square"></i>
                        Assignment
                      </div>
                      <div className="fourth-button">
                        <i className="bi bi-film"></i>
                        Activity Video
                      </div>
                      <div className="fifth-button">
                        <i className="bi bi-list-check"></i>
                        Activity Item List
                      </div>
                      <div className="sixth-button">
                        <i className="bi bi-graph-up"></i>
                        Simulation
                      </div>
                      <div className="seven-button" onClick={(e) => {
                        e.stopPropagation();
                        handleCompleteClick(topic.TopicCode, chapterCode);
                      }}>
                        <i className="bi bi-check"></i>
                        Complete
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}

      {/* Modal for video playback */}
      <Modal
        isOpen={modalData.isOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        className="video-modal"
        overlayClassName="video-modal-overlay"
      >
        <button className="close-modal" onClick={closeModal}>
          &times;
        </button>

        {/* Check if video URL exists and render iframe */}
        {modalData.videoUrl ? (
          <div className="media-player">
            <iframe
              width="100%"
              height="600"
              src={modalData.videoUrl}
              title="Media Player"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          <p>Video not available</p>
        )}
      </Modal>

    </div>
  );
};

export default BatchDetails;
