import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'font-awesome/css/font-awesome.min.css';
import '../Css(All)/TeacherHome.css';
import Sidebar from '../Teacher Part/Sidebar';
import Content from '../Teacher Part/Content';

// Modal Popup Component
const TeacherDetailsModal = ({ isOpen, onClose, teacherDetails }) => {
  if (!isOpen) return null;

  return (
    <div className="teacher-details-modal-overlay" onClick={onClose}>
      <div className="teacher-details-modal-content" onClick={(e) => e.stopPropagation()}>
        <h4 className="modal-title">Teacher Details</h4>
        <div className="teacher-details-modal-body">
          <p className='heading'>
            {teacherDetails.TeacherImage && (
              <img
                src={`data:image/png;base64,${teacherDetails.TeacherImage}`}
                alt="Teacher"
                className="teacher-image"
              />
            )}
          </p>
          <p className='heading'> {teacherDetails.TeacherName}</p>
          <p className='heading'> {teacherDetails.TeacherID}</p>
        </div>
        <button className="btn btn-close" onClick={onClose}></button>
      </div>
    </div>
  );
};

const Navbar = ({ selectedMenu, teacherName, onLogoClick }) => {
  const [teacherImage, setTeacherImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const updateTeacherImage = () => {
      const storedDetails = JSON.parse(sessionStorage.getItem('userDetails'));
      const image = storedDetails ? storedDetails.TeacherImage : null;
      setTeacherImage(image);
    };
    updateTeacherImage();
    window.addEventListener('storage', updateTeacherImage);
    return () => {
      window.removeEventListener('storage', updateTeacherImage);
    };
  }, []);

  const handleTeacherImageClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <div className="navbar-Logo" onClick={onLogoClick}>
          <img
            src="https://funscholar.infolabsinsight.com/fabicon.ico"
            alt="Logo"
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
      <div className="navbar-center">
        <span className="navbar-item">{selectedMenu || 'Dashboard'}</span>
      </div>
      <div className="navbar-end">
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <i className="bi bi-bell" style={{ fontSize: '25px', color: '#333', marginRight: '10px' }}></i>
          <span
            className="badge bg-danger"
            style={{
              position: 'absolute',
              top: '30px',
              right: '0',
              fontSize: '12px',
            }}
          >
          </span>
        </div>

        <span>{teacherName}</span>
        {teacherImage && (
          <img
            src={`data:image/png;base64,${teacherImage}`}
            alt="Teacher"
            style={{ borderRadius: '50%', width: '40px', height: '40px', marginLeft: '10px', cursor: 'pointer' }}
            onClick={handleTeacherImageClick}
          />
        )}
      </div>

      {/* Teacher Details Modal */}
      <TeacherDetailsModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        teacherDetails={JSON.parse(sessionStorage.getItem('userDetails'))}
      />
    </nav>
  );
};

const TeacherHome = () => {
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const teacherName = userDetails ? userDetails.TeacherName : 'Teacher';

  const [selectedMenu, setSelectedMenu] = useState('');

  const handleLogoClick = () => {
    setSelectedMenu('');
  };

  return (
    <div>
      <Navbar selectedMenu={selectedMenu} teacherName={teacherName} onLogoClick={handleLogoClick} />
      <div style={{ display: 'flex' }}>
        <Sidebar setSelectedMenu={setSelectedMenu} />
        <Content selectedMenu={selectedMenu} />
      </div>
    </div>
  );
};

export default TeacherHome;
